import alerts from '@imt/vue-admin-menus/src/store/alerts';
import notifications from '@imt/vue-admin-menus/src/store/notifications';
import businessRulesDebugger from '@imt/vue-business-rules-debugger/src/store/index';
import {configuration} from '@imt/vue-configuration/src/store';
import {asString, defaultParamMap} from '@imt/vue-list/src/utils/queryParams';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth, deepCase, stripIds} from '@imt/vue-toolbox/src/utils';
import {vueVersioning} from '@imt/vue-versioning/src/store';
import {webFormEditor} from '@imt/vue-web-form-editor/src/store';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import pick from 'lodash/pick';
import Vue from 'vue';
import Vuex from 'vuex';

import expressionBuilder from '@/components/ExpressionBuilder/store';
import {removeNestedComponents} from '@/utilities';

Vue.use(Vuex);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

export const authAxios = axiosWithAuth();

export const actions = {
    async createDraft({dispatch, state}, {version, queryParams = {}}) {
        const response = await authAxios.post(
            `/policy-systems/${state.currentPolicySystem.id}/rules/${version.ruleId}/versions/`,
            utils.dataFormatter.serialize({stuff: {id: version.id, type: 'Version'}})
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async createRule({dispatch, state}, data) {
        const response = await authAxios.post(
            `/policy-systems/${state.currentPolicySystem.id}/rules/`,
            utils.dataFormatter.serialize({stuff: stripIds(data, ['id'])}),
            {headers: {'content-type': 'application/vnd.api+json'}},
        );

        await dispatch('fetchRules');

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteRule({dispatch, state}, {ruleId, queryParams = {}}) {
        await authAxios.delete(`/policy-systems/${state.currentPolicySystem.id}/rules/${ruleId}/`);

        await dispatch('fetchRules', queryParams);
    },
    async deleteVersion({dispatch, state}, {version, queryParams = {}}) {
        await authAxios.delete(`/policy-systems/${state.currentPolicySystem.id}/rules/${version.ruleId}/versions/${version.id}/`);

        await dispatch('fetchRules', queryParams);
    },
    async disableRule({dispatch, state}, {rule, queryParams = {}}) {
        let data = {
            id: rule.id,
            type: 'Rule',
            disabledAt: null,
        };

        if (!rule.disabledAt) {
            data.disabledAt = dayjs().tz().format('YYYY-MM-DDT00:00:00Z');
        }

        const response = await authAxios.patch(
            `/policy-systems/${state.currentPolicySystem.id}/rules/${rule.id}/`,
            utils.dataFormatter.serialize({stuff: data}),
            {headers: {'content-type': 'application/vnd.api+json'}},
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async duplicateRule({dispatch}, {
        rule,
        versionId,
        policyPrefix,
    }) {
        let version = await dispatch('fetchVersionDetails', {ruleId: rule.id, versionId}),
            data = {
                ...pick(version, [
                    'actions',
                    'category',
                    'condition',
                    'description',
                    'helpWordingText',
                    'expressionGroups',
                    'canAccommodate',
                    'transactionType',
                ]),
                internalNotes: `${version.internalNotes} Rule duplicated from ${rule.title}.`,
                policySystemId: rule.policySystemId,
                ruleType: rule.ruleType,
                policySystemPrefix: policyPrefix,
            };

        if (rule.policyEffectiveDate) {
            data.policyEffectiveDate = rule.policyEffectiveDate;
        }

        // Remove nested components from the top level so they're not duplicated
        data = removeNestedComponents(data);
        // Remove any ids from the old objects so they get created as new objects.
        data = stripIds(data, [
            'id',
            'type',
            'syncId',
            'groupId',
            'versionId',
            'parentId',
            'draftOfId',
            'expressionId',
        ]);

        await dispatch('createRule', deepCase({
            ...data,
            type: 'Rule',
        }, 'snake', ['dataType']));
    },
    async testBusinessRules({state}, {
        ruleId,
        policyNumber
    }) {
        let url = `/policy-systems/${state.currentPolicySystem.id}/rules/`,
            data = {
                policyNumber: policyNumber,
                type: 'Run',
            };

        url += ruleId ? `${ruleId}/test/` : 'test/';

        const response = await authAxios.post(url, utils.dataFormatter.serialize({stuff: data}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchDiffs({state}, {
        oldVersionId,
        newVersionId,
        ruleId,
    }) {
        const response = await authAxios.get(`/policy-systems/${state.currentPolicySystem.id}/rules/${ruleId}/versions/${oldVersionId}/diffs/${newVersionId}/`);

        return deepCase(response.data, 'camel');
    },
    async fetchPolicySystems() {
        return authAxios.get(`${process.env.VUE_APP_ADMIN_URL_CMS}/api/v1/policy-systems/`);
    },
    async fetchRule({state}, id) {
        const response = await authAxios.get(`/policy-systems/${state.currentPolicySystem.id}/rules/${id}/?include=created_by,updated_by,versions.actions,versions.expression_groups.expressions.components,versions.created_by,versions.updated_by,versions.reverted_by`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchRules({
        commit,
        state,
    }, queryObject) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                'componentIdentifier': 'filter[component_sync_id]',
                'dataType': 'filter[data_type]',
                'search': 'filter[text]',
                'status': 'filter[status]',
                'actionType': 'filter[action_type]',
                'ruleType': 'filter[rule_type]',
                'title': 'filter[title]',
                'pageType': 'filter[page_type]',
                'internalNotes': 'filter[internal_notes]',
                'transactionType': 'filter[transaction_type]',
                'helpWordingText': 'filter[help_wording_text]',
                'underwritingFix': 'filter[underwriting_fix]',
                'pageId': 'filter[page_id]',
                'description': 'filter[description]',
                'policyEffectiveDate__gte': 'filter[policy_effective_date__gte]',
                'policyEffectiveDate__lte': 'filter[policy_effective_date__lte]',
            }),
            url = `/policy-systems/${state.currentPolicySystem.id}/rules/?include=created_by,updated_by,versions.actions,versions.expression_groups.expressions.components,versions.created_by,versions.updated_by,versions.reverted_by`;

        // Fix the formatting of double-underscore query parameters in the query string.
        url = url.replace(/date_([g|l]te)/g, 'date__$1');

        const response = await authAxios.get(`${url}${queryString}`);

        commit('SET_DATA', {field: 'rules', value: utils.dataFormatter.deserialize(response.data)});
        commit('SET_DATA', {field: 'totalRuleCount', value: response.data.meta.pagination.count});
    },
    async fetchVersionDetails({state}, {ruleId, versionId}) {
        const response = await authAxios.get(`/policy-systems/${state.currentPolicySystem.id}/rules/${ruleId}/versions/${versionId}/?include=actions,created_by,updated_by,expression_groups.children,expression_groups.expressions.components.children`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async obsoleteRule({dispatch, state}, {
        ruleId,
        obsoleteDate,
        queryParams = {}
    }) {
        let data = {
            id: ruleId,
            obsoleteAt: dayjs(obsoleteDate).tz().toISOString(),
            type: 'Rule',
        };

        const response = await authAxios.patch(`/policy-systems/${state.currentPolicySystem.id}/rules/${ruleId}/`,
            utils.dataFormatter.serialize({stuff: data}),
            {
                headers: {'content-type': 'application/vnd.api+json'},
                withCredentials: true,
            },
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async publishVersion({dispatch, state}, {
        version,
        publishDate,
        queryParams = {},
    }) {
        let data = {
            id: version.id,
            publishedAt: dayjs(publishDate).tz().toISOString(),
            type: 'Version',
        };

        const response = await authAxios.patch(`/policy-systems/${state.currentPolicySystem.id}/rules/${version.ruleId}/versions/${version.id}/`,
            utils.dataFormatter.serialize({stuff: data}),
            {
                headers: {'content-type': 'application/vnd.api+json'},
                withCredentials: true,
            },
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async revertVersion({dispatch, state}, {
        version,
        queryParams = {},
        revertDate,
    }) {
        let data = {
            id: version.id,
            revertedAt: dayjs(revertDate).tz().toISOString(),
            type: 'Version',
        };

        const response = await authAxios.patch(`/policy-systems/${state.currentPolicySystem.id}/rules/${version.ruleId}/versions/${version.id}/`,
            utils.dataFormatter.serialize({stuff: data}),
            {
                headers: {'content-type': 'application/vnd.api+json'},
                withCredentials: true,
            },
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchPageOptions({dispatch, state}, type) {
        if (type) {
            const action = type === 'page' ? 'expressionBuilder/fetchPages' : type === 'form' ? 'expressionBuilder/fetchForms' : 'expressionBuilder/fetchClassCodes';

            return await dispatch(action, state.currentPolicySystem.id);
        } else {
            return [];
        }
    },
    async unscheduleObsolete({dispatch, state}, {ruleId, queryParams = {}}) {
        let data = {
            id: ruleId,
            type: 'Rule',
            obsoleteAt: null,
        };

        const response = await authAxios.patch(`/policy-systems/${state.currentPolicySystem.id}/rules/${ruleId}/`,
            utils.dataFormatter.serialize({stuff: data}),
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async unschedulePublish({dispatch, state}, {version, queryParams = {}}) {
        const response = await authAxios.patch(
            `/policy-systems/${state.currentPolicySystem.id}/rules/${version.ruleId}/versions/${version.id}/`,
            utils.dataFormatter.serialize({
                stuff: {
                    type: 'Version',
                    id: version.id,
                    publishedAt: null,
                },
            }),
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async unscheduleRevert({dispatch, state}, {version, queryParams = {}}) {
        const response = await authAxios.patch(
            `/policy-systems/${state.currentPolicySystem.id}/rules/${version.ruleId}/versions/${version.id}/`,
            utils.dataFormatter.serialize({
                stuff: {
                    type: 'Version',
                    id: version.id,
                    revertedAt: null,
                },
            }),
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
    async updateVersion({dispatch, state}, {versionData, queryParams = {}}) {
        const response = await authAxios.patch(
            `/policy-systems/${state.currentPolicySystem.id}/rules/${versionData.ruleId}/versions/${versionData.id}/`,
            utils.dataFormatter.serialize({stuff: versionData}),
            {headers: {'content-type': 'application/vnd.api+json'}},
        );

        await dispatch('fetchRules', queryParams);

        return utils.dataFormatter.deserialize(response.data);
    },
};

export const mutations = {
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    SET_CURRENT_POLICY_SYSTEM(state, currentPolicySystem) {
        state.currentPolicySystem = currentPolicySystem;
        localStorage.setItem('currentPolicySystem', JSON.stringify(currentPolicySystem));
    },
    SET_DATA(state, {field, value}) {
        state[field] = value;
    },
    SET_SEARCH_FIELDS(state, searchField) {
        if ((typeof searchField.value !== 'boolean' && !searchField.value) || (Array.isArray(searchField.value) && !searchField.value.length)) {
            Vue.delete(state.searchFields, searchField.key);
        } else {
            Vue.set(state.searchFields, searchField.key, searchField.value);
        }
    },
};

export const state = () => {
    return {
        currentPolicySystem: JSON.parse(localStorage.getItem('currentPolicySystem')) || null,
        rules: [],
        searchFields: {},
        totalRuleCount: 0
    };
};

export default new Vuex.Store({
    actions,
    modules: {
        alerts,
        configuration,
        expressionBuilder,
        notifications,
        toolbox,
        webFormEditor,
        vueVersioning,
        businessRulesDebugger
    },
    mutations,
    state: state(),
});
